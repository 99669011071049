// material-ui
import React, { useEffect, useRef } from 'react';
import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    TextField,
    Checkbox,
    useMediaQuery,
    Box,
    Rating,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio,
    Badge,
} from '@mui/material';
import RecipeReviewCard from 'components/RecipeViewCard/RecipeViewCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    cancelTask,
    finalAttendanceAll,
    getResourceTask,
    getTaskExportSurvey,
    getTasks,
    getTasksPreventiva,
    getTechinalDispatch,
    reopenTask,
} from 'services/task';
import Tasks from './Tasks';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { Link, useNavigate } from 'react-router-dom';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useTheme, makeStyles } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { BrowserView, MobileView } from 'react-device-detect';
import ReportTask from './ReportTask';
import AddButton from 'components/Buttons/AddButton';
import Alert from '@mui/material/Alert';
import BasicModal from '../../../components/Modal/BasicModal';
import AnimateButton from 'ui-component/extended/AnimateButton';
import NumberFormat from 'react-number-format';
import { getSatisfactionQuestions } from 'services/surveySatisfactionQuestions';
import ModalFilter from 'components/Modal/ModalFilter';
import { postSatisfaction } from 'services/surveySatisfaction';
import { evaluations } from './Docs/docs';
import copy from 'copy-to-clipboard';
import { getBrlFormatDate } from 'utils/date';
import Loading from 'components/Loading/Loading';
import { gridSpacing } from 'store/constant';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';
import imageCompression from 'browser-image-compression';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useScriptRef from 'hooks/useScriptRef';
import { display } from '@mui/system';
import Excel from '@mui/icons-material/Task';

// ==============================|| Index ||============================== //
const GridTask = () => {
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85',
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
        },
    });
    const initialStateOptions = {
        typeOfEquipament: [],
        typeOfProblem: [],
        unit: [],
        typeOfOs: [],
        natureOfOperation: [],
        family: [],
        categories: [],
        statusOs: [],
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const scriptedRef = useScriptRef();
    const infoRef = useRef();
    const theme = useTheme();
    const id_role = useSelector((state) => state.auth.user.perfil_id);
    const navigate = useNavigate();
    const path = window.location.pathname;
    const dispatch = useDispatch();
    const page = useSelector((state) => state.task.page);
    const rowsPerPage = useSelector((state) => state.task.rowsPerPage);
    const general = useSelector((state) => state.task.general);
    const initialDate = useSelector((state) => state.task.initialDate);
    const finalDate = useSelector((state) => state.task.finalDate);
    const idStatus = useSelector((state) => state.task.idStatus);
    const idUnit = useSelector((state) => state.user.unit || '');
    const idTypeOs = useSelector((state) => state.task.idTypeOs || '');
    const keyword = useSelector((state) => state.task.keyword || '');
    const equipamento_id = useSelector(
        (state) => state.task.equipamento_id || ''
    );
    const os = useSelector((state) => state.task.os || '');
    const objectStatus = useSelector((state) => state.task.objectStatus || []);
    const idNatureOfOperation = useSelector(
        (state) => state.task.idNatureOfOperation || ''
    );
    const moduleOs =
        path === '/corretivas' ? 1 : path === '/preventivas' ? 2 : '';
    const modulePost =
        path === '/corretivas'
        ? '/nova_corretiva'
        : path === '/preventivas'
            ? '/nova_preventiva'
            : '';
    const titleModule =
        moduleOs === 1 ? 'CORRETIVAS' : moduleOs === 2 ? 'PREVENTIVAS' : '';
    const [tasks, setTasks] = React.useState([]);
    const [options, setOptions] = React.useState(initialStateOptions);
    const [filterAvanced, setFilterAvanced] = React.useState(false);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [idCancelTask, setIdCancelTask] = React.useState('');
    const [idReopenTask, setIdReopenTask] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalReopen, setOpenModalReopen] = React.useState(false);
    const [displayItem, setDisplayItem] = React.useState(
        screen.width < 768 ? true : false
    );
    const [questions, setQuestions] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [idTaskEvaluation, setIdTaskEvaluation] = React.useState('');
    const [openModalEvaluation, setOpenModalEvaluation] = React.useState(false);
    const withLink = (to, children) => <Link to={to}>{children}</Link>;
    const actions = [
        { icon: withLink(`${modulePost}`, <PersonAdd />), name: 'Nova OS' },
    ];
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [modalInfo, setModalInfo] = React.useState(false);
    const [informacoes, setInformacoes] = React.useState({});
    const [selectedQr, setSelectedQr] = React.useState([]);
    const [selected, setSelected] = React.useState('');
    const checkboxRef = React.useRef();
    const [todosMarcados, setTodosMarcados] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openModalAll, setOpenModalAll] = React.useState(false);
    const [isDisabled, setIdDisabled] = React.useState(true);
    const [technicals, setTechnicals] = React.useState('');
    const [finalPhoto, setFinalPhoto] = React.useState('');
    React.useEffect(() => {
        getAllTasks();
    }, [page, moduleOs, idUnit, rowsPerPage]);
    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_TASK', payload: newPage });
    };
    const handleChangeQtd = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_TASK', payload: newPage });
    };
    React.useEffect(() => {
        getResource();
    }, []);
    const handleCloseModalEvaluation = () => {
        setAnswers([]);
        setIdTaskEvaluation('');
        setOpenModalEvaluation(false);
    };
    function getResource() {
        getResourceTask().then((resp) =>
            setOptions({
                ...resp.data,
                natureOfOperation: resp.data.natureOfOperation.filter(
                    (desc) => desc.flag_corretiva === moduleOs
                ),
                statusOs:
                    moduleOs === 1
                        ? resp.data.statusOs
                        : resp.data.statusOs.filter((desc) => desc.id !== 4),
            })
        );
    }
    const handleCloseModal = () => setOpenModal(false);
    const handleCloseModalAll = () => setOpenModalAll(false);
    const handleCloseInfo = () => setModalInfo(false);
    const handleCloseModalReopen = () => setOpenModalReopen(false);
    const handleOpenCancel = (idTask) => {
        setIdCancelTask(idTask);
        setOpenModal(true);
    };
    const handleOpenModalAll = (idTask) => {
        setOpenModalAll(true);
    };
    const info = (desc) => {
        setModalInfo(true);
        setInformacoes(desc);
    };

    const handleReopenTask = (idTask) => {
        setIdReopenTask(idTask);
        setOpenModalReopen(true);
    };

    function setIdStatus() {
        let idStatusNew = [];
        objectStatus.forEach((element) => {
            let valueId = parseInt(element.split(' -')[0]);
            idStatusNew = [...idStatusNew, valueId];
        });
        return idStatusNew;
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch({
            type: 'SET_ROWS_PER_PAGE_TASK',
            payload: parseInt(event),
        });
        dispatch({ type: 'SET_PAGE_TASK', payload: 1 });
    };

    const handleChangeQr = (desc, value = false) => {
        console.log(checkboxRef);
        if (desc.status_id !== 3 && desc.status_id !== 5) {
            let arrayState = {
                ...selected,
                [desc.id]: selected[desc.id] ? !selected[desc.id] : true,
            };
            if (arrayState[desc.id]) {
                setSelectedQr([...selectedQr, desc]);
            } else {
                setSelectedQr(selectedQr.filter((val) => val.id != desc.id));
            }
            setSelected(arrayState);
        }
    };

    function getAllTasks(
        pageNumber = page,
        statusIdAttr,
        typeOsIdAttr,
        natureOfOperationIdAttr,
        OsAttr,
        typeEquipamentIdAttr,
        keywordAttr
    ) {
        setLoading(true);
        let statuId =
        statusIdAttr === '' ? statusIdAttr : idStatus ? setIdStatus() : '';
        let typeOsId =
        typeOsIdAttr === '' ? typeOsIdAttr : idTypeOs ? idTypeOs.id : '';
        let osNumber = OsAttr === '' ? OsAttr : os;
        let natureOfOperationId =
        natureOfOperationIdAttr === ''
            ? natureOfOperationIdAttr
            : moduleOs === 2
            ? 11
            : idNatureOfOperation
                ? idNatureOfOperation.id
                : '';
        let typeEquipamentId =
        typeEquipamentIdAttr === ''
            ? typeEquipamentIdAttr
            : equipamento_id
            ? equipamento_id.id
            : '';
        let keywordGeneral = keywordAttr === '' ? OsAttr : keyword;
        getTasksPreventiva(
            pageNumber,
            rowsPerPage,
            general,
            initialDate,
            finalDate,
            statuId,
            idUnit,
            typeOsId,
            moduleOs,
            natureOfOperationId,
            osNumber,
            '',
            typeEquipamentId,
            keywordGeneral
        )
        .then((resp) => setTasks(resp.data))
        .then(() => setLoading(false));
    }
    const cancel = () => {
        try {
            cancelTask(idCancelTask)
                .then((resp) => {
                    setOpenModal(false);
                    setSuccess(resp.data.success);
                    getAllTasks();
                    setTimeout(() => {
                        setSuccess('');
                    }, 2000);
                })
                .catch((e) => {
                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 2000);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const reopen = () => {
        try {
            let modulePath = path.split('/')[1];
            let moduleDispatch = '';
            if (modulePath === 'corretivas') {
                moduleDispatch = 'corretiva';
            } else {
                moduleDispatch = 'preventiva';
            }
            reopenTask(idReopenTask)
                .then((resp) => {
                    setOpenModalReopen(false);
                    setSuccess(resp.data.success);
                    getAllTasks();
                    setTimeout(() => {
                        setSuccess('');
                        navigate({ pathname: `/${moduleDispatch}/${idReopenTask}/edit` });
                    }, 1000);
                })
                .catch((e) => {
                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 2000);
                });
        } catch (err) {
            console.log(err);
        }
    };
    // Função para buscar questões e avaliações
    const fetchQuestionsAndEvaluations = async () => {
        try {
            const resp = await getSatisfactionQuestions();
            setQuestions(resp.data);
            // Supondo que você também precise buscar as avaliações
            // const evalResp = await getEvaluations();
            // setEvaluations(evalResp.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenModalEvaluation = async (desc) => {
        try {
            // Configurar as respostas
            if (desc.surveySatisfaction) {
                const initialAnswers = desc.surveySatisfaction.answers.map((res) => ({
                    id: res.pesquisa_satisfacao_pergunta_id,
                    weight: res.resposta,
                }));
                setAnswers(initialAnswers);
            } else {
                setAnswers([]);
            }

            // Buscar as questões e abrir o modal
            const resp = await getSatisfactionQuestions();
            setQuestions(resp.data);
            setIdTaskEvaluation(desc?.id);
            setOpenModalEvaluation(true);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (openModalEvaluation) {
            console.log('Modal aberto com questões e respostas:', {
            questions,
            answers,
        });
        // Isso garantirá que as respostas sejam atualizadas corretamente
        // Quando o modal for aberto, as respostas serão usadas
        }
    }, [openModalEvaluation, answers]); // Adicione `answers` como dependência para garantir que as atualizações sejam refletidas

    const handleChangeEvaluation = (e) => {
        let id = e.target.name;
        let arrayData = answers.filter((desc) => parseInt(desc.id) !== parseInt(id));
        let object = {
            id: id,
            weight: e.target.value,
        };

        setAnswers([...arrayData, object]);
    };

    const postEvaluation = () => {
        try {
            setError('');
            if (answers.length !== questions.length) {
                return setError('Responda todas as perguntas !');
            }

            const data = {
                os_id: idTaskEvaluation,
                data: answers,
            };
            postSatisfaction(data)
                .then((resp) => {
                    setAnswers([]);
                    setIdTaskEvaluation('');
                    setOpenModalEvaluation(false);
                    setSuccess(resp.data.success);
                    setTimeout(() => {
                        setSuccess('');
                    }, 3000);
                })
                .then(() => {
                    getAllTasks();
                });
        } catch (err) {
            console.log(err);
        }
    };

    function renderButton() {
        console.log(id_role);
        if (moduleOs === 3) {
            return <AddButton href={modulePost} />;
        } else if (moduleOs === 1 && id_role !== 3) {
            return <AddButton href={modulePost} />;
        } else if (moduleOs === 2 && id_role !== 3) {
            return <AddButton href={modulePost} />;
        }
    }
    const copyToClipboard = () => {
        let infoText = infoRef.current.innerText;
        copy(infoText);
    };

    function mascaraDeTelefone(telefone) {
        let textoAjustado;
        if (telefone && telefone.length > 0) {
            const textoAtual = telefone;
            const isCelular = textoAtual.length === 11;
            if (isCelular) {
                const parte1 = textoAtual.slice(0, 2);
                const parte2 = textoAtual.slice(2, 7);
                const parte3 = textoAtual.slice(7, 11);
                textoAjustado = `(${parte1})${parte2}-${parte3}`;
            } else {
                const parte1 = textoAtual.slice(0, 2);
                const parte2 = textoAtual.slice(2, 6);
                const parte3 = textoAtual.slice(6, 10);
                textoAjustado = `(${parte1})${parte2}-${parte3}`;
            }
        }
        return textoAjustado;
    }

    const handleMarcarTodos = (items) => {
        const newSelected = {};
        const newSelectedQr = [];

        if (todosMarcados) {
            // Se todos estão marcados, desmarque-os
            tasks?.data.forEach((item) => {
                if (item.status_id !== 3 && item.status_id !== 5) {
                newSelected[item.id] = false; // Desmarcar
                }
            });
            setSelectedQr([]); // Limpa a lista de selecionados
        } else {
            // Se não estão marcados, marque todos
            tasks?.data.forEach((item) => {
                if (item.status_id !== 3 && item.status_id !== 5) {
                    newSelected[item.id] = true; // Marcar
                    newSelectedQr.push(item); // Adicionar ao array de selecionados
                }
            });
        }

        setSelected(newSelected);
        setSelectedQr(newSelectedQr);
        setTodosMarcados(!todosMarcados); // Inverte o estado
    };
    function formatDateTime(dateTime) {
        let dateTimeFull = new Date(dateTime);
        const year = dateTimeFull.getFullYear();
        const month = dateTimeFull.getUTCMonth() + 1;
        const day = dateTimeFull.getDate().toString().padStart(2, '0');
        let date = `${year}-${month}-${day} ${dateTimeFull.getHours()}:${dateTimeFull.getMinutes()}`;
        return date;
    }
    function technicalPerOs() {
        getTechinalDispatch().then((resp) => setTechnicals(resp.data.techinal));
    }
    useEffect(() => {
        technicalPerOs();
    }, []);
    async function handleFinalImageUpload(event) {
        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1920,
        };
        try {
            const compressedFile = await imageCompression(imageFile, options);
            setFinalPhoto(compressedFile);
        } catch (error) {
            console.log(error);
        }
    }

    const download = () => {
        try {
            // if (!initialDate && !finalDate) {
            //     return [
            //         setError('Preencha uma das datas.'),
            //         setTimeout(() => {
            //             setError('');
            //         }, 3000)
            //     ];
            // }
            getTaskExportSurvey(initialDate, finalDate, idUnit).then((resp) => {
                let blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' });
                let link = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = 'file.xlsx';
                a.href = link;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setSuccess('Logs gerados com sucesso.');
                setTimeout(() => {
                    setSuccess('');
                }, 2000);
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
        <ModalFilter
            width="60%"
            open={openModalAll}
            title="Fechar Vários"
            handleClose={handleCloseModalAll}
            content={
                <Formik
                    initialValues={{
                        description: '',
                        submit: null,
                        finalPhoto: '',
                        problemResolved: true,
                        technical: '',
                    }}
                    // enableReinitialize
                    validationSchema={Yup.object().shape({
                        technical:
                            id_role == 1
                            ? Yup.object().required('Técnico obrigatório')
                            : '',
                        description: Yup.string().required('Descrição obrigatório'),
                    })}
                    onSubmit={async (values,{ setErrors, setStatus, setSubmitting, resetForm }) => {
                        try {
                            setOpenModalAll(false);
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            setLoading(true);
                            const data = new FormData();
                            let ids = selectedQr.map((desc) => desc.id);
                            data.append('technical', values.technical?.id);
                            data.append('finalPhoto', finalPhoto);
                            data.append('description', values.description);
                            data.append('problemResolved', values.problemResolved === true ? 1 : 0);
                            data.append('ids', JSON.stringify(ids));
                            console.log(data);
                            finalAttendanceAll(data)
                                .then((resp) => {
                                    setSuccess(resp.data.success)
                                    setSelectedQr([]);
                                    setSelected('');
                                    setLoading(false);
                                    getAllTasks();
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000)
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setError(e.response.data.error);
                                });
                        } catch (err) {
                            console.log(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                                setLoading(false);
                            }
                        }
                    }}
                >
                    {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <>
                            <div style={{ display: 'block', marginTop: '40px' }}>
                                <MainCard spacing={gridSpacing} style={{ marginTop: 15, display: loading ? 'none' : 'block' }}>
                                    {loading && (
                                        <Grid container alignItems="center" justifyContent="center">
                                            <MainCard
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Loading color="#00008B" type="cubes" />
                                            </MainCard>
                                        </Grid>
                                    )}
                                    <div style={{ display: loading ? 'none' : 'block' }}>

                                        <form noValidate onSubmit={handleSubmit}>
                                            <Grid container spacing={matchDownSM ? 0 : 2}>
                                                <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                                    <Autocomplete
                                                        fullWidth
                                                        select
                                                        label="Técnico"
                                                        id="technical"
                                                        value={values.technical}
                                                        name="technical"
                                                        onBlur={handleBlur}
                                                        onChange={(e, newValue) => setFieldValue('technical', newValue)}
                                                        options={technicals}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Técnico"
                                                                helperText={
                                                                touched.technical && errors.technical
                                                                    ? errors.technical
                                                                    : ''
                                                                }
                                                                error={Boolean(touched.technical && errors.technical)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginTop: 3 }}>
                                                    <TextField
                                                        fullWidth
                                                        error={Boolean(touched.description && errors.description)}
                                                        label="Descrição"
                                                        multiline
                                                        rows={4}
                                                        id="description"
                                                        type="text"
                                                        value={values.description}
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        helperText={
                                                            touched.description && errors.description
                                                                ? errors.description
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginTop: 3 }}>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Foto Depois"
                                                            multiline
                                                            type="text"
                                                            disabled={true}
                                                            value={finalPhoto ? finalPhoto.name : ''}
                                                        />
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="upload picture"
                                                            component="label"
                                                        >
                                                            <input
                                                                name="finalPhoto"
                                                                id="finalPhoto"
                                                                hidden
                                                                accept="image/*"
                                                                type="file"
                                                                onChange={(e) => handleFinalImageUpload(e)}
                                                            />
                                                            <PhotoCamera />
                                                        </IconButton>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginTop: 1 }}>
                                                    <FormControlLabel
                                                        value={values.problemResolved}
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                checked={values.problemResolved}
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        'problemResolved',
                                                                        !values.problemResolved
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Problema Resolvido ?"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                alignItems="end"
                                                justifyContent="end"
                                                sx={{ mt: 3 }}
                                            >
                                                <Grid item>
                                                    <Box sx={{ mt: 2, mr: 3 }}>
                                                        <ThemeProvider theme={themeButton}>
                                                            <AnimateButton>
                                                                <Button
                                                                    disableElevation
                                                                    disabled={isSubmitting}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                    Finalizar
                                                                </Button>
                                                            </AnimateButton>
                                                        </ThemeProvider>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ mt: 2, mr: 3 }}>
                                                        <ThemeProvider theme={themeButton}>
                                                            <AnimateButton>
                                                                <Button
                                                                    disableElevation
                                                                    disabled={isSubmitting}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="neutral"
                                                                    onClick={() => navigate(-1)}
                                                                >
                                                                    Voltar
                                                                </Button>
                                                            </AnimateButton>
                                                        </ThemeProvider>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                                </Box>
                                            )}
                                        </form>
                                    </div>
                                </MainCard>
                            </div>
                        </>
                    )}
                </Formik>
            }
        />
        <BasicModal
            open={openModal}
            title="Cancelar Chamado"
            handleClose={handleCloseModal}
            description="Tem certeza que deseja cancelar a os ?"
            onDelete={cancel}
        />
        <BasicModal
            open={openModalReopen}
            title="Reabrir Chamado"
            handleClose={handleCloseModalReopen}
            description="Tem certeza que deseja reabrir a os ?"
            onDelete={reopen}
        />
        <ModalFilter
            open={modalInfo}
            title="Informações do Chamado"
            handleClose={handleCloseInfo}
            width="600px"
            content={
            <>
                <ul ref={infoRef} id={`info-${informacoes?.id}`}>
                <li>{`Chamado: OS${informacoes?.id}`}</li>
                <li>{`Chamado Inventys: ${informacoes?.codigo_inventys}`}</li>
                <li>{`Local: ${informacoes?.unidade}`}</li>
                <li>{`Solicitante: ${informacoes?.solicitante}`}</li>
                <li>{`Telefone: ${mascaraDeTelefone(informacoes?.telefone)}`}</li>
                <li>{`Descrição: ${informacoes?.descricao}`}</li>
                <li>{`Tipo de Chamado: ${informacoes?.tipo_os}`}</li>
                <li>{`Equipamento: ${informacoes?.tipo_equipamento}`}</li>
                <li>{`Ambiente: ${informacoes?.ativo}`}</li>
                <li>{`Data Abertura: ${informacoes?.data_abertura}`}</li>
                <li>{`SLA: ${informacoes?.sla}`}</li>
                <li>{`Data de 1° atendimento: ${informacoes?.sla_atendimento}`}</li>
                {informacoes?.natureza_id == 11 ? (
                    <li>{`Prazo Final Preventiva: ${getBrlFormatDate(informacoes?.prazo_final_preventiva)}`}</li>
                ) : (
                    <li>{`Prazo Final: ${informacoes?.sla_solucao}`}</li>
                )}
                </ul>
                <Grid container alignItems="right" justifyContent="right" sx={{ mt: 3 }}>
                    <Grid item>
                        <Box sx={{ mt: 2, mr: 3 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={loadingButton}
                                    fullWidth
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    color="error"
                                    onClick={handleCloseInfo}
                                >
                                    Fechar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ mt: 2, mr: 3 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={loadingButton}
                                    fullWidth
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={copyToClipboard}
                                >
                                    Copiar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </>
            }
        />
        <MainCard sx={{ height: '100%', background: '#F5F5F5', border: 0 }} xs={12} md={12} sm={12} container>
            {loading && (
                <Grid container alignItems="center" justifyContent="center">
                    <MainCard
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Loading color="#015641" type="cubes" />
                    </MainCard>
                </Grid>
            )}
            <div style={{ display: loading ? 'none' : 'block' }}>

            <Grid xs={12} md={12} sm={12} container>
                <h1
                    style={{
                    font: 'normal normal bold 24px Myriad Pro',
                    letterSpacing: '0px',
                    color: 'var(--unnamed-color-015641)',
                    color: 'black',
                    opacity: 1,
                    padding: 15,
                    marginLeft: '2%',
                    }}
                >
                    {`OS - ${titleModule}`}
                </h1>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
            </Grid>
            {error || success ? (
                <Snackbar open={true} autoHideDuration={6000}>
                    <Alert
                        severity={error ? 'error' : success ? 'success' : ''}
                        sx={{
                            width: '100%',
                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                            color: '#FFF',
                        }}
                        >
                            {error ? error : success ? success : ''}
                    </Alert>
                </Snackbar>
            ) : (
                ''
            )}
            <MobileView>
                <Grid container spacing={2} alignItems="left" justifyContent="left">
                    <Grid item xs={6} sm={1} sx={{ mt: 4 }}>
                        <AnimateButton>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setDisplayItem(!displayItem)}
                            >
                                Filtros
                            </Button>
                        </AnimateButton>
                    </Grid>
                    <Grid item xs={6} sm={1} sx={{ mt: 4 }}>
                        <h3>{`${tasks.total} chamados`}</h3>
                    </Grid>
                </Grid>
            </MobileView>
            <div style={{ display: displayItem === true ? 'none' : 'block' }}>
                <Grid container spacing={2} alignItems="left" justifyContent="left">
                    <Grid item xs={12} sm={1} sx={{ marginTop: 3 }}>
                        <NumberFormat
                            fullWidth
                            id="outlined-quantidade"
                            type="text"
                            label="OS"
                            value={os}
                            onChange={(e) =>
                            dispatch({
                                type: 'SET_OS_TASK_FILTER',
                                payload: e.target.value,
                            })
                            }
                            name="os"
                            customInput={TextField}
                            decimalScale={0}
                            allowNegative
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                                labelId="status_id"
                                multiple
                                name="status_id"
                                id="status_id"
                                value={idStatus}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'SET_IDSTATUS_TASK_FILTER',
                                        payload:
                                            typeof e.target.value === 'string'
                                            ? e.target.value.split(',')
                                            : e.target.value,
                                        objectStatus: e.target.value,
                                    })
                                }
                                input={<OutlinedInput label="status_id" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {options.statusOs.map((option) => (
                                    <MenuItem key={option.id} value={`${option.id} - ${option.label}`}>
                                        <Checkbox checked={idStatus.indexOf(`${option.id} - ${option.label}`) > -1} />
                                        <ListItemText primary={option.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ marginTop: 3 }}>
                        {
                            moduleOs == 2 ? (
                                <Autocomplete
                                    fullWidth
                                    select
                                    label="Equipamento"
                                    id="equipamento_id"
                                    type="text"
                                    value={equipamento_id || ''}
                                    name="equipamento_id"
                                    onChange={(e, newValue) =>
                                        dispatch({
                                            type: 'SET_IDEQUIPAMENTO_FILTER',
                                            payload: newValue == null ? '' : newValue,
                                        })
                                    }
                                    options={options.typeOfEquipament}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Equipamento" />
                                    )}
                                />
                            ) : (
                                <Autocomplete
                                    fullWidth
                                    select
                                    label="Tipo de OS"
                                    id="type_os"
                                    type="text"
                                    value={idTypeOs || ''}
                                    name="type_os"
                                    onChange={(e, newValue) =>
                                        dispatch({
                                            type: 'SET_IDTYPEOSTASK_FILTER',
                                            payload: newValue == null ? '' : newValue,
                                        })
                                    }
                                    options={options.typeOfOs}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tipo de OS" />
                                    )}
                                />
                            )
                        }
                    </Grid>
                    {
                        moduleOs === 1 && (
                            <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                                <Autocomplete
                                    fullWidth
                                    select
                                    label="Natureza"
                                    id="nature_of_operation"
                                    type="text"
                                    value={idNatureOfOperation || ''}
                                    name="nature_of_operation"
                                    onChange={(e, newValue) =>
                                        dispatch({
                                            type: 'SET_IDNATUREOFOPERATION_FILTER',
                                            payload: newValue == null ? '' : newValue,
                                        })
                                    }
                                    options={options.natureOfOperation}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Natureza" />
                                    )}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12} sm={moduleOs === 1 ? 2 : 3} sx={{ marginTop: 3 }}>
                        <TextField
                            fullWidth
                            id="outlined-initialDate"
                            type="date"
                            label="Data Início Criação"
                            value={initialDate}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_INITIALDATE_TASK_FILTER',
                                    payload: e.target.value,
                                })
                            }
                            name="initialDate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={moduleOs === 1 ? 2 : 3} sx={{ marginTop: 3 }}>
                        <TextField
                            fullWidth
                            id="outlined-finalDate"
                            type="date"
                            label="Data Fim Criação"
                            value={finalDate}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_FINALDATE_TASK_FILTER',
                                    payload: e.target.value,
                                })
                            }
                            name="finalDate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    {
                        moduleOs == 2 && (
                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                <TextField
                                    fullWidth
                                    id="outlined-keyword"
                                    type="text"
                                    label="Palavra Chave"
                                    value={keyword}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'SET_KEYWORD_TASK_FILTER',
                                            payload: e.target.value,
                                        })
                                    }
                                    name="keyword"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <Grid container spacing={1} alignItems="right" justifyContent="right" sx={{ mt: 4 }}>
                <Grid item>
                    <AnimateButton>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => download()}
                            startIcon={<Excel />}
                        >
                            Avaliações
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item>
                    <AnimateButton>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => getAllTasks(1)}
                        >
                            Buscar
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item>
                    <AnimateButton>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={(e) => [
                                dispatch({
                                    type: 'SET_CLEAR_TASK_FILTER',
                                    idNatureOfOperation:
                                        moduleOs === 1
                                            ? ''
                                            : options.natureOfOperation.filter((desc) => desc.id === 11),
                                }),
                                getAllTasks('', '', moduleOs === 1 ? '' : 11, '', '', '', ''),
                            ]}
                        >
                            Limpar
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>

            </div>
            {
                filterAvanced === true && (
                    <ReportTask options={options} getAllTasks={getAllTasks} />
                )
            }
            {
                !displayItem && <h3>{`Total: ${tasks.total} chamados`}</h3>
            }
            {
                moduleOs == 2 && idUnit !== 14725896312 && (
                    <Grid container>
                        <Grid item xs={6} sm={2} sx={{ mt: 4 }}>
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleMarcarTodos}
                                >
                                    Marcar Todos
                                </Button>
                            </AnimateButton>
                        </Grid>
                        {
                            selectedQr.length > 0 && idUnit !== 14725896312 && (
                                <Grid item xs={6} sm={6} sx={{ mt: 4 }}>
                                    <AnimateButton>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={handleOpenModalAll}
                                        sx={{ display: 'flex', alignItems: 'center' }} // Para alinhar o badge e o texto
                                    >
                                        <Badge 
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }} 
                                            badgeContent={selectedQr.length}  
                                            color="secondary"
                                            sx={{ marginRight: 2 }} // Espaço entre o badge e o texto
                                        >
                                            <span />
                                        </Badge>
                                        Finalizar Selecionados
                                    </Button>
                                    </AnimateButton>
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            <Grid sx={{ mt: 4 }} xs={12} md={12} sm={12} container alignItems="center" justifyContent="center">
                <ModalFilter
                    width="60%"
                    open={openModalEvaluation}
                    title="Avaliação do Chamado"
                    handleClose={handleCloseModalEvaluation}
                    content={
                        <>
                            <Grid container style={{ marginTop: 15 }} spacing={matchDownSM ? 0 : 2}>
                                {questions.map((desc) => {
                                    // Encontre a resposta para a pergunta atual
                                    const answer = answers.find((ans) => parseInt(ans.id) === parseInt(desc.id));
                                    // Defina o valor padrão do `RadioGroup` como a resposta encontrada ou um valor padrão se não houver resposta
                                    const defaultValue = answer ? answer.weight : '';

                                    return (
                                        <Grid item xs={12} md={12} sm={12} key={desc.id}>
                                            <Typography component="legend">
                                                <b>{`${desc.ordem}. ${desc.descricao}`}</b>
                                            </Typography>
                                            <FormControl
                                                disabled={id_role !== 4 ? true : false}
                                                component="fieldset"
                                                onChange={(event) =>
                                                    handleChangeEvaluation(event, desc.id)
                                                }
                                            >
                                                <RadioGroup
                                                    row
                                                    aria-label="evaluation"
                                                    name={desc.id}
                                                    value={defaultValue}
                                                >
                                                    {evaluations.map((res) => (
                                                        <FormControlLabel
                                                            key={res.value}
                                                            value={res.value}
                                                            control={<Radio />}
                                                            label={`${res.value}. ${res.label}`}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid container alignItems="right" justifyContent="right" sx={{ mt: 3 }}>
                                <Grid item>
                                    <Box sx={{ mt: 2, mr: 3 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={loadingButton}
                                                fullWidth
                                                size="large"
                                                type="button"
                                                variant="contained"
                                                color="error"
                                                onClick={handleCloseModalEvaluation}
                                            >
                                                Cancelar
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ mt: 2, mr: 3 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={loadingButton}
                                                fullWidth
                                                size="large"
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                onClick={postEvaluation}
                                            >
                                                Enviar
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    }
                />
                {tasks.data && (
                    <>
                        <Tasks
                            id_role={id_role}
                            onReopen={handleReopenTask}
                            navigate={navigate}
                            moduleOs={moduleOs}
                            tasks={tasks.data}
                            onCancel={handleOpenCancel}
                            onModalEvaluation={handleOpenModalEvaluation}
                            info={info}
                            handleChangeQr={handleChangeQr}
                            selected={selected}
                            checkboxRef={checkboxRef}
                        />
                    </>
                )}
                {tasks.data && (
                    <Grid container alignItems="center" justifyContent="center" xs={12} md={12} sm={12} sx={{ padding: 3 }}>
                        {idUnit && equipamento_id && moduleOs == 2 && (
                            <Stack spacing={2}>
                                <Button onClick={() => handleChangeRowsPerPage(tasks.total)}>
                                    Mostrar Todos
                                </Button>
                            </Stack>
                        )}
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(
                                    parseInt(tasks.total) / parseInt(rowsPerPage)
                                )}
                                variant="outlined"
                                color="primary"
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Stack>
                    </Grid>
                )}
                </Grid>
            </div>
        </MainCard>
        {idUnit !== 14725896312 &&
            id_role !== 4 &&
            id_role !== 7 &&
            renderButton()}
        </>
    );
};

export default GridTask;
